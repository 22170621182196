<template>
  <div v-loading="fetchingPaymentPlans" class="Pricing p-b-xxxl">
    <c-x-navigation>
      <go-back-button slot="left" />
    </c-x-navigation>
    <c-x-plans-section v-if="arePaymentPlansFetched" class="m-b-l" />
    <c-x-testimonials-section />
    <c-x-faq-section />
    <c-x-plans-comparison-section />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CXNavigation from '@/components/cx/CXNavigation'

import GoBackButton from '@/components/cx/GoBackButton'
import CXFaqSection from '@/components/cx/pricing/CXFaqSection'
import CXPlansSection from '@/components/cx/pricing/CXPlansSection'
import CXTestimonialsSection from '@/components/cx/pricing/CXTestimonialsSection'
import CXPlansComparisonSection from '@/components/cx/pricing/CXPlansComparisonSection'

/**
 * @module Pricing
 */
export default {
  name: 'Pricing',

  components: {
    GoBackButton,
    CXFaqSection,
    CXNavigation,
    CXPlansSection,
    CXTestimonialsSection,
    CXPlansComparisonSection
  },

  computed: {
    ...mapState('cx/payment', ['fetchingPaymentPlans']),
    ...mapGetters('cx/payment', ['arePaymentPlansFetched', 'paymentPlansMapForInterval', 'arePaymentPlansFetched'])
  }
}
</script>
