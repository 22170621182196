<template functional>
  <td
    :class="{ 'has-text-primary': props.value }"
    class="has-text-centered"
  >
    <v-icon v-if="props.value" type="check-o" />
  </td>
</template>

<script>

/**
 * @module PlansComparisonTableBoolCell
 */
export default {
  name: 'PlansComparisonTableBoolCell',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>
