<template>
  <div class="CXFaqSection p-v-xxxl">
    <div class="container container--padded">
      <heading size="3" color="black">
        {{ $t('components.cx_faq_section.heading') }}
      </heading>
      <div class="m-b-l is-size-5">
        {{ $t('components.cx_faq_section.subheading') }}
      </div>
      <faq-section
        :title="$t('components.cx_faq_section.purchase')"
        :questions="questions.purchase"
      />
      <faq-section
        :title="$t('components.cx_faq_section.general')"
        :questions="questions.general"
      />
    </div>
  </div>
</template>

<script>
import FaqSection from '@/components/cx/FaqSection'

function transformToI18NList (collection) {
  return collection.map((question) => {
    return {
      title: this.$t(`components.faq.${question}.title`),
      answer: this.$t(`components.faq.${question}.answer`)
    }
  })
}

const PURCHASE_QUESTIONS = ['how_to_upgrade', 'billing', 'number_of_users_choice', 'invoice', 'plan_change', 'additional_fees', 'discounts']

const GENERAL_QUESTIONS = ['newsroom_after_upgrade', 'standalone_monitoring', 'how_to_invite_users', 'private_data_safety', 'more_questions']

/**
 * @module CXFaqSection
 */
export default {
  name: 'CXFaqSection',
  components: { FaqSection },
  computed: {
    questions () {
      return {
        purchase: transformToI18NList.call(this, PURCHASE_QUESTIONS),
        general: transformToI18NList.call(this, GENERAL_QUESTIONS)
      }
    }
  }
}
</script>
