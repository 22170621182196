<template>
  <div class="responsive-table">
    <table
      v-if="arePaymentPlansFetched"
      id="comparison-table"
      class="PlansComparisonTable table is-fullwidth is-bordered"
    >
      <thead>
        <tr>
          <td class="PlansComparisonTable__Empty" />
          <td class="PlansComparisonTable__PlanTitle">
            {{ $t('pages.cx.pricing.plans.essential.title') }}
          </td>
          <td class="PlansComparisonTable__PlanTitle">
            {{ $t('pages.cx.pricing.plans.plus.title') }}
          </td>
          <td class="PlansComparisonTable__PlanTitle">
            {{ $t('pages.cx.pricing.plans.professional.title') }}
          </td>
          <td class="PlansComparisonTable__PlanTitle">
            {{ $t('pages.cx.pricing.plans.enterprise.title') }}
          </td>
        </tr>
        <tr>
          <td class="PlansComparisonTable__Empty">
            {{ $t('components.plans_comparison_table.price_per_user_per_month') }}
          </td>
          <td class="has-text-centered">
            {{ $t('components.plans_comparison_table.free') }}
          </td>
          <td class="has-text-centered">
            €{{ formatMoney(plusAmount, true, 'EUR', plus.currency, false) }}
          </td>
          <td class="has-text-centered">
            €{{ formatMoney(proAmount, true, 'EUR', pro.currency, false) }}
          </td>
          <td class="has-text-centered">
            <router-link :to="{ name: 'cx.contact_us' }" class="has-text-primary">
              {{ $t('components.plans_comparison_table.on_request') }}
            </router-link>
          </td>
        </tr>
      </thead>
      <template
        v-for="(categoryFeatures, category) in features"
      >
        <tr
          :key="category"
          class="PlansComparisonTable__Category is-cursor-pointer"
          @click="visibleSections[category] = !visibleSections[category]"
        >
          <td colspan="5" class="has-background-info is-size-5">
            <div class="is-flex is-aligned-middle">
              <v-icon :type="visibleSections[category] ? 'caret-down': 'caret-right'" />
              <div class="is-expanded">
                {{ $t(`components.plans_comparison_table.categories.${category}`) }}
              </div>
            </div>
          </td>
        </tr>
        <tbody :key="`t_body_${category}`" :class="{ 'is-hidden': !visibleSections[category] }">
          <tr
            v-for="(feature, index) in categoryFeatures"
            :key="`${category}_${index}`"
          >
            <td>{{ $t(`components.plans_comparison_table.features.${feature[0]}`) }}</td>
            <plans-comparison-table-bool-cell :value="feature[1]" />
            <plans-comparison-table-bool-cell :value="feature[2]" />
            <plans-comparison-table-bool-cell :value="feature[3]" />
            <plans-comparison-table-bool-cell :value="feature[4]" />
          </tr>
        </tbody>
      </template>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlansComparisonTableBoolCell from '@/components/cx/pricing/PlansComparisonTableBoolCell'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'

/**
 * @module PlansComparisonTable
 */
export default {
  name: 'PlansComparisonTable',
  components: { PlansComparisonTableBoolCell },
  mixins: [FormatMoneyMixin],
  data () {
    return {
      features: {
        // [title, Essential, Plus, Pro, Enterprise]
        customer_support: [
          ['chat_and_email_support', true, true, true, true],
          ['onboarding', false, false, true, true],
          ['hotline', false, false, true, true],
          ['custom_adjustments', false, false, true, true],
          ['custom_onboarding', false, false, true, true]
        ],
        media_monitoring: [
          ['web', true, true, true, true],
          ['facebook', false, true, true, true],
          ['twitter', false, true, true, true],
          ['instagram', false, true, true, true],
          ['youtube', false, true, true, true],
          ['reddit', false, true, true, true],
          ['review', false, true, true, true],
          ['mobile_app', false, true, true, true],
          ['monitoring_streams', false, true, true, true],
          ['daily_mail', false, true, true, true],
          ['print', false, true, true, true]
        ],
        media_functionality: [
          ['financial_value', false, false, true, true],
          ['insights', false, false, true, true],
          ['kpi', false, false, true, true],
          ['clipping_archive', false, false, true, true]
        ],
        media_reports: [
          ['kpi', false, false, true, true]
        ],
        connect: [
          ['relations_database', false, false, true, true],
          ['influencer_database', false, false, true, true],
          ['create_media_lists', false, false, true, true]
        ],
        create_and_distribute: [
          ['newsroom', true, true, true, true],
          ['create_stories', true, true, true, true],
          ['embed_newsroom', true, true, true, true],
          ['create_press_releases', false, false, true, true],
          ['distribute_to_relations', false, false, true, true],
          ['tracking_effects', false, false, true, true]
        ]
      },
      visibleSections: {
        customer_support: false,
        media_monitoring: false,
        media_functionality: false,
        media_reports: false,
        connect: false,
        create_and_distribute: false
      }
    }
  },
  computed: {
    ...mapGetters('cx/payment', ['installment', 'arePaymentPlansFetched', 'getPlanCommitmentFor']),
    pro () {
      return this.getPlanCommitmentFor('professional')
    },
    plus () {
      return this.getPlanCommitmentFor('plus')
    },
    plusAmount () {
      if (!this.plus) return 0
      return this.plus.amount / this.installment
    },
    proAmount () {
      if (!this.pro) return 0
      return this.pro.amount / this.installment
    }
  }
}
</script>

<style lang="scss">
@import '~utils';

.PlansComparisonTable {
  background-color: transparent;

  tr > td:first-of-type {
    border-left: none;
  }

  .PlansComparisonTable__Empty {
    border: none;
  }

  &__PlanTitle {
    width: 1%;
    padding-left: $padding-large !important;
    padding-right: $padding-large !important;
    background: $info;
    font-weight: $hf__font-weight-bold;

    &:not(:last-of-type) {
      border-right-color: $white !important;
    }
  }
}
</style>
