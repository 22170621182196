<template>
  <div class="FaqSection">
    <div class="FaqSection__Title is-size-5 has-background-info p-l-l p-a-s has-text-weight-semibold">
      {{ title }}
    </div>
    <div class="FaqSectionQuestions-Wrapper p-v-l">
      <el-collapse class="FaqSectionQuestions">
        <el-collapse-item
          v-for="(question, index) in questions"
          :key="index"
          :name="index"
          class="FaqSectionQuestion"
        >
          <div slot="title" class="FaqSectionQuestion__Title">
            {{ question.title }}
          </div>
          <div>{{ question.answer }}</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
/**
 * @module FaqSection
 */
export default {
  name: 'FaqSection',
  props: {
    title: {
      type: String,
      default: ''
    },
    /** @type {{ title:string, answer: string }[]} */
    questions: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.FaqSectionQuestions {
  border: none;

  @include desktop {
    &-Wrapper {
      padding-left: $spacing-xxxlarge;
    }
  }
}

.FaqSectionQuestion {
  &.is-active {
    margin-bottom: $margin;
  }

  &__Title {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;

    span {
      flex: 1 1 auto;
    }
  }

  &__Title__Line {
    flex: 8 0 auto;
    height: 1px;
    background: $grey-lighter;
    margin-right: 1rem;
  }

  .el-collapse-item__wrap {
    border-bottom-color: $grey-lighter;
  }

  .el-collapse-item__header {
    border: none;
    font-weight: $hf__font-weight-bold;
    font-size: $size-5;
    height: auto;
    line-height: 1.5;
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row-reverse;

    .el-collapse-item__arrow {
      color: $grey-light;
      margin-right: $margin;
      line-height: 1.5;
      font-size: $size-3;
      /* override the element-ui angle with HF caret triangle */
      &:before {
        font-family: $icomoon-font-family;
        content: $hf-caret-right;
      }
    }
  }
}
</style>
