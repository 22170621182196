<template>
  <div class="CXPlansComparisonSection container container--padded">
    <heading size="3" color="black">
      {{ $t('components.cx_plans_comparison_section.headline') }}
    </heading>
    <plans-comparison-table />
  </div>
</template>

<script>
import PlansComparisonTable from '@/components/cx/pricing/PlansComparisonTable'

/**
 * @module CXPlansComparisonSection
 */
export default {
  name: 'CXPlansComparisonSection',
  components: { PlansComparisonTable }
}
</script>
